<template>
  <div>
    <list :type="1"/>
  </div>
</template>
<script>
import list from './Index.vue'

export default {
  name: 'Index',
  components: {
    list,

  },
}
</script>
<style scoped lang="less">

</style>
